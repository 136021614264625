<template>
    <div class="box">
        <div class="sorterBox">
            <p class="sorterTitle sorter">Sort:</p>
            <a class="sorter" id="AZ" @click="sort('Name', 'AZ')" v-bind:class="[sortBy === 'Name' ? sortDirection : '']">A to Z</a>
            <a class="sorter" id="Rel" @click="sort('Relates', 'Rel')" v-bind:class="[sortBy === 'Relates' ? sortDirection : '']">Most Relatable</a>
            <a class="sorter" id="No" @click="sort('Entry_No', 'No')" v-bind:class="[sortBy === 'Entry_No' ? sortDirection : '']">Newest</a>
        </div>
        <div class="allWords">
            <mr-scroll id="hi">
                <div class="entry" v-for="(post, index) in Sortedposts" v-bind:item="post" v-bind:index="index" v-bind:key="post._id">
                    <p class="mini-text">No. {{ post.Entry_No }}</p>
                    <h1 class="wordAll">
                        <router-link :to="{ name: 'WordPage', params: { slug: post.Slug } }" class="name">{{ post.Name }}</router-link
                        ><em class="type">{{ post.Type }}.</em>
                    </h1>
                    <p class="desc_All">
                        {{ post.Desc }}
                    </p>
                    <p class="error" v-if="error">{{ error }}</p>
                    <a class="relate_text_all" v-on:click="upVote(post._id, num)" :id="post._id">
                        <svg
                            version="1.1"
                            class="heart"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 19 16.8"
                            style="enable-background:new 0 0 19 16.8;"
                            xml:space="preserve"
                        >
                            <path
                                style="fill: filling"
                                class="st0"
                                d="M16.1,2.8L16.1,2.8c0.4,0.4,0.8,0.9,1,1.5c0.2,0.6,0.4,1.2,0.4,1.8s-0.1,1.2-0.4,1.8c-0.2,0.6-0.6,1.1-1,1.5
	l0,0l-6.3,6.1l0,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1l0,0L2.9,9.2l0,0C2.1,8.4,1.6,7.3,1.5,6.2
	C1.5,5,1.9,3.9,2.7,3c0.8-0.9,1.9-1.4,3.1-1.6C7,1.3,8.2,1.6,9.2,2.3l0.3,0.2l0.3-0.2c0.9-0.7,2.1-1,3.2-0.9
	C14.2,1.5,15.3,2,16.1,2.8z"
                            />
                        </svg>

                        <span class="relates" ref="counter">{{ post.Relates }} </span><span class="relatesText" ref="counter"> {{ innertexts }} </span>
                    </a>
                </div>
            </mr-scroll>
        </div>
    </div>
</template>

<script>
import PostService from "../PostService";
import json from "../assets/posts.json";

export default {
    // name: 'NewerWebsiteHome',

    // components: { ComponentName },

    // directives: { DirectiveName },
    computed: {
        Sortedposts: function() {
            return this.posts
                .slice()
                .filter((posts) => posts.Entry_No <= this.Week)
                .sort((p1, p2) => {
                    let modifier = 1;
                    if (this.sortDirection === "desc") modifier = -1;
                    if (p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier;
                    if (p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
                    return 0;
                });
        },
    },

    data() {
        return {
            posts: json,
            error: "",
            num: 1,
            innertexts: "Loading...",
            likes: JSON.parse(localStorage.getItem("Likes")) || [],
            liked: false,
            sortBy: "Name",
            sortDirection: "asc",
            sorts: 0,
            Week: 52,
        };
    },
    async mounted() {
        try {
            this.posts = await PostService.getPosts();
            console.log(this.posts);
            this.$nextTick(() => {
                var likes = JSON.parse(localStorage.getItem("Likes")) || [];
                let Entry = document.getElementsByClassName("relate_text_all");
                console.log(Entry);

                for (let i = 0; i < Entry.length; i++) {
                    let id = Entry[i].getAttribute("id");
                    console.log(Entry[i]);

                    if (likes.includes(id)) {
                        console.log(Entry[i]);
                        var relateNumHeart = document.querySelector(`#${CSS.escape(id)} svg .st0`);
                        var relateNumText = document.querySelector(`#${CSS.escape(id)} .relatesText`);
                        var relateNumDiv = document.querySelector(`#${CSS.escape(id)} .relates`);
                        var relateNum = relateNumDiv.textContent;
                        relateNumHeart.style.fill = "#238235";
                        relateNumText.textContent = "people, including you, relate to this word";

                        if (relateNum == 1) {
                            relateNumText.textContent = "person, just you, relates to this word";
                        }
                    } else {
                        relateNumDiv = document.querySelector(`#${CSS.escape(id)} .relates`);
                        relateNum = relateNumDiv.textContent;
                        relateNumText = document.querySelector(`#${CSS.escape(id)} .relatesText`);
                        if (relateNum == 1) {
                            relateNumText.textContent = "person relates to this word";
                        } else{
                            relateNumText.textContent = "people relate to this word";

                        }
                    }
                }
            });
        } catch (err) {
            this.error = err.message;
        }
    },
    async created() {
        try {
            console.log("before");
this.Week=100;
console.log(this.Week)
        } catch (err) {
            this.error = err.message;
        }
    },

    methods: {
        sort: function(s, id) {
            if (s === this.sortBy) {
                this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
            } else if (id == "AZ") {
                if (document.getElementById(id).textContent == "A to Z") {
                    this.sortDirection = "asc";
                } else {
                    this.sortDirection = "desc";
                }
            } else if (id == "Rel") {
                if (document.getElementById(id).textContent == "Least Relatable") {
                    this.sortDirection = "asc";
                } else {
                    this.sortDirection = "desc";
                }
            } else if (id == "No") {
                if (document.getElementById(id).textContent == "Newest") {
                    this.sortDirection = "desc";
                } else {
                    this.sortDirection = "asc";
                }
            }
            if (this.sortBy) this.sortBy = s;
            if (id == "AZ") {
                if (this.sortDirection == "desc") {
                    document.getElementById(id).textContent = "Z to A";
                } else {
                    document.getElementById(id).textContent = "A to Z";
                }
            } else if (id == "Rel") {
                if (this.sortDirection == "asc") {
                    document.getElementById(id).textContent = "Least Relatable";
                } else {
                    document.getElementById(id).textContent = "Most Relatable";
                }
            } else if (id == "No") {
                if (this.sortDirection == "asc") {
                    document.getElementById(id).textContent = "Oldest";
                } else {
                    document.getElementById(id).textContent = "Newest";
                }
            }
            var divy = document.getElementsByClassName("mr-scroll_content");
            var onlydivy = divy[0];
            onlydivy.scrollTop = 0;
        },

        sortChanged(type) {
            console.log(type);
            var sorters = document.getElementsByClassName("sorter");
            for (let i = 0; i < sorters.length; i++) {
                sorters[i].classList.remove("sorted");
            }
            sorters[type].classList.add("sorted");
        },

        async upVote(id, num) {
            var likes = JSON.parse(localStorage.getItem("Likes")) || [];
            var relateNumHeart = document.querySelector(`#${CSS.escape(id)} svg .st0`);
            var relateNumDiv = document.querySelector(`#${CSS.escape(id)} .relates`);
            var relateNumText = document.querySelector(`#${CSS.escape(id)} .relatesText`);
            var relateNum = relateNumDiv.textContent;

            if (likes.includes(id) == true || likes == null) {
                num = -1;
                likes = likes.filter(function(a) {
                    return a !== id;
                });
                localStorage.setItem("Likes", JSON.stringify(likes));

                relateNumHeart.style.fill = "none";

                relateNum--;
                relateNumDiv.textContent = relateNum;

                relateNumText.textContent = "people relate to this word";
                if (relateNum == 1) {
                    relateNumText.textContent = "person relates to this word";
                }
            } else {
                num = 1;
                likes.push(id);
                localStorage.setItem("Likes", JSON.stringify(likes));

                relateNumHeart.style.fill = "#238235";

                relateNum++;
                relateNumDiv.textContent = relateNum;
                relateNumText.textContent = "people, including you, relates to this word";

                if (relateNum == 1) {
                    relateNumText.textContent = "person, just you, relates to this word";
                }
            }
            await PostService.upVote(id, num);
            var counter = document.getElementsByClassName("relates");
            counter.innerhtml;
            //this.posts = await PostService.getWeekly();
        },
    },
};
</script>

<style lang="scss" scoped>
$SeeHeight: 3 * $padding;

.box {
    display: grid;
    grid-template-rows: $padding/2 1fr;
    grid-template-columns: 1fr;
    height: 100%;
    flex: 1;
}

.hidden {
    display: none;
}

.sorter {
    cursor: pointer;
    padding-right: $padding/3;
}

.allWords {
    overflow: hidden;
    display: flex;
    margin-top: -1px;
    margin-bottom: -1px;
}
.mr-scroll_content {
    scroll-snap-type: y mandatory;
}
.sorterBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid $black;
    padding-left: $padding;
}

.asc {
    text-decoration: underline;
}

.desc {
    text-decoration: underline;
}
.desc_All {
    max-width: 100ch;
}
.sorterTitle {
    font-weight: 800;
}

.entry {
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    padding: $padding/2 $padding;
    border-top: 1px solid $black;
    scroll-snap-align: start;
    text-align: left;
}

.entry:first-of-type {
    border-top: 0;
}

.HomeEntry {
    width: 100%;
    padding: $padding;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    flex-direction: column;
    text-align: left;
}

.wordAll {
    margin-top: 0.4rem;
    font-size: 2rem;
}

.type {
    margin-left: 1rem;
    font-weight: 500;
}
.mini-text {
    font-size: 1rem;
    margin: 0.25rem;
    margin-left: 0;
}

.relate_text_all {
    color: $green;
    margin-top: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    margin-bottom: 0.4rem;
}

.heart {
    width: 1rem;
    margin-right: 0.5rem;
}

.st0 {
    stroke-width: 1.5px;
    stroke: $green;
    fill: none;
    overflow: visible;
}
.relate_text:hover .st0 {
    fill: $green;
}

@media only screen and (max-width: 480px) {
    .entry {
        padding: $padding/2 $padding/2;
        padding-right: calc(#{$padding/2} + 1rem);
        margin-right: -3rem;
        min-height: auto;
    }
    .sorterBox {
        padding-left: $padding/2;
    }
    .sorter {
        padding-right: $padding/4;
    }
    .relate_text_all {
    color: $green;
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    margin-bottom: 0rem;
}

    .relate_text_all {
        padding-bottom: 0;

    }
        .relate_text_all {
        padding-bottom: 0;

    }
    .mini-text{
        padding-top: 0;
    }
}
</style>
