import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // <---
import SmartTable from 'vuejs-smart-table'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import Scroll from '@mr-scroll/vue';


createApp(App).use(router).use(SmartTable).use(PerfectScrollbar).use(Scroll).mount('#app');

