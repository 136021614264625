// import { rejects } from "assert";
// import { post } from "../../server/routes/api/posts";
import axios from "axios";

const url = "api/posts/";

class PostService {
    // Get Posts
    static getPosts() {
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    // var currentdate = new Date();
                    // var oneJan = new Date(currentdate.getFullYear(),0,1);
                    // var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
                    // var result = Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7)-5;
                    const data = res.data;
                    // const weekly = [];
                    // for (let i = 0; i < data.length; i++) {
                    //     if (data[i].Entry_No <= result) {
                    //         weekly.push(data[i]);
                        
                    // }
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

        // Get Post
        static getPost(slug) {
            return new Promise((resolve, reject) => {
                console.log(`${url}${slug}`)
                axios
                    .get(`${url}${slug}`)
                    .then((res) => {
                        const data = res.data;
                        //console.log(data);
                        const word = [];
                        word.push(data);
                        resolve(word
                        );
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        }

    // Get Weekly
    static getWeekly() {
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    var currentdate = new Date();
                    var oneJan = new Date(currentdate.getFullYear(),0,1);
                    var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
                    var result = Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7)-5;
                    const data = res.data;
                    const weekly = [];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].Entry_No == result) {
                            weekly.push(data[i]);
                        }
                    }
                    resolve(weekly);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // Create Post
    static insertPost(text) {
        return axios.post(url, {
            text,
        });
    }

    // Deleate Post
    static deletePost(id) {
        return axios.delete(`${url}${id}`);
    }

    // Upvote
    static upVote(id, num) {
        return axios.post(`${url}${id}`, {
            id,
            votes: num,
        });
    }
}

export default PostService;
