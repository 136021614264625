<template>
    <div class="container">
        <div id="nav">
            <div class="left navy">
                <router-link class="navlink title" to="/">The Dictionary of Obscure Ai Sorrows</router-link>
            </div>
            <div class="right navy">
                <router-link class="navlink" to="/seeall">See All</router-link>
                <router-link class="navlink" to="/about">About</router-link>
                <a class="navlink" href="mailto:aisorrows@mildlyupset.com" target="_blank" rel="noopener noreferrer">Contact</a>
            </div>
        </div>
        <div id="content">
            <router-view />
        </div>
        <div id="footer">
            <div class="left">
                <p class="footer_info">
                    All words generated by
                    <a href="https://beta.openai.com/" target="_blank" rel="noopener noreferrer">OpenAi’s GPT-3</a> and trained on entries from
                    <a href="https://www.dictionaryofobscuresorrows.com/" target="_blank" rel="noopener noreferrer">The Dictionary of Obscure Sorrows.</a>
                </p>
            </div>
            <div class="right">
                <p class="fred">
                    A Project by
                    <a class="navlink no_margin" href="https://www.fredwordie.com" target="_blank" rel="noopener noreferrer">Fred Wordie</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
// import PostComponent from './components/PostComponent.vue'

export default {
  name: 'App',
  components: {
  },
     created() {
        window.addEventListener('resize', () => { 
  document.querySelector(':root').style
    .setProperty('--vh', window.innerHeight/100 + 'px');
})
    },
}
</script>
<style src="@mr-scroll/core/dist/styles.css"></style>
<style lang="scss">
.container {
    display: grid; /* displays flex-items (children) inline */
    grid-template-columns: 1fr;
    grid-auto-rows: auto 1fr auto;
    grid-template-areas: "header" "main" "footer";
    height: 100vh;

}
body {
    margin: 0px;
    font-size: 16px;
    border: 1px solid $black;
    font-family: "Eb Garamond";
    overflow: hidden;
    height: 100vh;
    height: -webkit-fill-available;
    }

html {
    height: 100%;
}
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $black;
    height: 100%;
    background-color: $cream;
    position: relative;
    border: 1px solid $black;
  height: -webkit-fill-available;
  height: fill-available;
}

#nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $padding;
    border-bottom: 1px solid $black;

    width: 100%;
    background-color: $cream;
    grid-area: header;
}
#footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $padding;
    border-top: 1px solid $black;
    grid-area: footer;

    width: 100%;
    background-color: $cream;
}
#content {
    grid-area: main;
    overflow: hidden;
    display: flex;
}
.navlink {
    margin-right: $padding/2;
    color: $black;
    text-decoration: none;
}
.navlink:last-of-type {
    margin-right: $padding;
}
.router-link-active {
    font-weight: 800;
}
.title {
    font-weight: 700;
}
.mobspacer{
    display: none;
}
.left {
    padding-left: $padding;
    max-width: 50%;
    text-align: left;
}
.fred {
    margin-right: $padding;
}
a:hover {
    text-decoration: underline;
}
.no_margin {
    margin: 0px !important;
}

.name {
    color: $black;
    text-decoration: none;
}
.name:hover {
    color: $black;
    text-decoration: underline;
}
a{
    color:$green;
    text-decoration: none;
}
.type {
    margin-left: 1rem;
    font-weight: 500;
}
.mini-text {
    font-size: 1rem;
    margin: 0.25rem;
    margin-left: 0rem;
}
.descri {
    text-align: left;
    font-size: 1.5rem;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
}
#WoD{
max-width: 1000px;
}
.relate_text {
    color: $green;
    margin-top: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.heart {
    width: 1rem;
    margin-right: 0.5rem;
}

.st0 {
    stroke-width: 1.5px;
    stroke: $green;
    fill: none;
    overflow: visible;
}
.relate_text:hover .st0 {
    fill: $green;
}
.relates {
    display: inline-block;
    margin-right: 3px;
}

h1 {
    margin-top: 0.4rem;
    margin-bottom: 0rem;
    font-size: 2.5rem;
    font-weight: 700;
}
.wordAll {
    margin-top: 0px;
    font-size: 1.5rem;
}
.mr-scroll_content {
    overflow-x: hidden !important;

}
.mr-scroll_bar--v {
    border-left: 1px solid $black;
}
.mr-scroll_bar-thumb {
    margin: 0px;
    background-color: $black;
    border-radius: 0px;
    opacity: 1;
    border-bottom: 7px solid $black;
}
.mr-scroll_bar-track {
    margin: 0px;
}
.desc_All {
    text-align: left;
    font-size: 1.25rem;
    margin-top: 0.2rem;
    margin-bottom: 0.4rem;
}
@supports (-webkit-touch-callout: none) {
  .container {
    /* The hack for Safari */
           height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
  height: stretch;
  }
}

@media only screen and (max-width: 480px) {

    .HomeEntry{
        padding:0;
 
    }
    .mr-scroll_content{
    padding-right: $padding/2;
    -webkit-overflow-scrolling: touch !important;;
    overflow-y: scroll !important;;
    }
    body {
        font-size: 14px;
        background-color: grey;
    }

    html{
                font-size: 14px;

    }

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: $black;
        background-color: $cream;
        position: relative;
        border: 1px solid $black;
    }
.mini-text {
    font-size: 1rem;
    margin: 0.25rem;
    margin-left: 0rem;
            padding-top: $padding/2;

}

    .word{
        margin-bottom: 0.5rem;
    }

    #nav {
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-direction: column;
        min-height: $padding;
        border-bottom: 1px solid $black;
        width: 100%;
        background-color: $cream;
    }
    #footer {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        //min-height: $padding;
        height: auto;
        border-top: 1px solid $black;
        width: 100%;
        padding: 1rem 0px;
        background-color: $cream;
    }

    .navlink {
        margin-right: $padding/4;
        color: $black;
        text-decoration: none;
    }
    .footer_info {
        margin: 0px;
    }
    .fred {
        margin: 0px;
        margin-top: 0.5rem;
    }
    .navlink:last-of-type {
        margin-right: 0px;
    }
    .title {
        font-weight: 700;
    }
    .left {
        padding-left: $padding/2;
        padding-right: $padding/2;
        max-width: 100%;
        text-align: left;
    }
    .right {
        padding-left: $padding/2;
        padding-right: $padding/2;
    }

    .navy.left {
        min-height: $padding/2;
        border-bottom: 1px solid $black;
    }
    .navy {
        display: flex;

        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
    }

    .navy.right {
        min-height: $padding/2;
    }
    a:hover {
        text-decoration: underline;
    }
    .no_margin {
        margin: 0px !important;
    }
    .wordAll {
        font-size: 1.8rem;
    }
    .desc_All {
        font-size: 1.2rem;
    }
    .descri {
        margin-top: 0px;
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
    }
    .relate_text_all {
        margin-top: 0.5rem;
        padding-bottom: $padding/2;

    }

}
</style>
