<template>
    <mr-scroll>
        <div class="padder">
            <h1 class="wordAll abouthead">
                What is this website?
            </h1>
            <p class="desc_All aboout">
                This website is a collection of new words and definitions that attempt to describe specific and intrinsically human experiences. These words
                were generated by OpenAI's machine learning model <a href="https://beta.openai.com/"  target="_blank" rel="noopener noreferrer">GPT-3</a>, after being given a selection of words from  <a href="https://www.dictionaryofobscuresorrows.com/"  target="_blank" rel="noopener noreferrer">The Dictionary of Obscure Sorrows</a> as a
                prompt.
            </p>
            <h1 class="wordAll abouthead">
                Why am I doing this?
            </h1>
            <p class="desc_All aboout">
                In short, the promise of the GPT-3 is that it can produce human-like text. For me, this is made much more interesting if the text it can create
                is both novel and moving in some way. I see the creation of words that describe often felt but still undefined human emotions, as a micro
                example to test this.
            </p>
            <h1 class="wordAll abouthead abouthead">
                How are these words generated?
            </h1>
            <p class="desc_All">
                All words were generated in OpenAI's playground, a space where you can play around with the GPT-3 API without needing to know what APIs are.
                After tweaking a few of the default settings, I input the prompt "Made up words that describe very specific human emotions:" and gave GPT-3 five
                random words from The Dictionary of Obscure Sorrows as examples. I then selected the best (most novel and intelligible) words and definitions,
                and repeated the process with another five random examples. Some definitions were generated with vocable that were already taken or that just
                felt inappropriate, these were then fed back to GPT-3 to find more fitting vocable.
            </p>
            <a class="desc_All but" id="But" @click="show()">
                See an example
            </a>
            <div id="box" class="hidden">
                <p>
                    Made up words that describe very specific human emotions:<br /><br />
                    Word: backmasking<br />
                    Type: n.<br />
                    Definition: the instinctive tendency to see someone as you knew them in their youth, a burned-in image of grass-stained knees, graffitied
                    backpacks or handfuls of birthday cake superimposed on an adult with a degree, an illusion formed when someone opens the door to your
                    emotional darkroom while the memory is still developing.<br /><br />
                    Word: liberosis<br />
                    Type: n.<br />
                    Definition: the desire to care less about things&mdash;to loosen your grip on your life, to stop glancing behind you every few steps, afraid
                    that someone will snatch it from you before you reach the end zone&mdash;rather to hold your life loosely and playfully, like a volleyball,
                    keeping it in the air, with only quick fleeting interventions, bouncing freely in the hands of trusted friends, always in play.<br /><br />
                    Word: anchorage<br />
                    Type: n.<br />
                    Definition: the desire to hold on to time as it passes, like trying to keep your grip on a rock in the middle of a river, feeling the weight
                    of the current against your chest while your elders float on downstream, calling over the roar of the rapids, &ldquo;Just let go&mdash;it's
                    okay&mdash;let go.&rdquo;<br /><br />
                    Word: daguerreologue<br />
                    Type: n.<br />
                    Definition: an imaginary interview with an old photo of yourself, an enigmatic figure who still lives in the grainy and color-warped house
                    you grew up in, who may well spend a lot of their day wondering where you are and what you&rsquo;re doing now, like an old grandma whose
                    kids live far away and don&rsquo;t call much anymore.<br /><br />
                    Word: adomania<br />
                    Type: n.<br />
                    Definition: the sense that the future is arriving ahead of schedule, that all those years with fantastical names like '2013' are bursting
                    from their hypothetical cages into the arena of the present, furiously bucking the grip of your expectations while you lean and slip in your
                    saddle, one hand reaching for reins, the other waving up high like a schoolkid who finally knows the answer to the question.<br /><br />
                    Word: ...<br />
                </p>
            </div>
            <h1 class="wordAll abouthead">
                How can I report an issue with a word?
            </h1>
            <p class="desc_All">
                If for any reason you have an issue with a word in this dictionary (e.g. you believe it is already an existing word or is wrongly labelled)
                feel free to get in <a href="mailto:aisorrows@mildlyupset.com" target="_blank" rel="noopener noreferrer">touch</a>.
            </p>
            <h1 class="wordAll abouthead">
                Will there be new words added?
            </h1>
            <p class="desc_All">
                Yeah, a new word will be added every week till the end of 2021, at least. Keep up to date by following our <a href="https://twitter.com/aisorrows" target="_blank" rel="noopener noreferrer">Twitter</a>.
            </p>
        </div>
    </mr-scroll>
</template>

<script>
export default {
    data() {
        return {
            num: 0,
        };
    },
    methods: {
        show: function() {
            console.log("sort");
            if (this.num == 0) {
                document.getElementById("box").classList.remove("hidden");
                document.getElementById("But").innerText = "Hide the example";
                this.num = 1;
            } else {
                document.getElementById("box").classList.add("hidden");
                document.getElementById("But").innerText = "See an example";
                this.num = 0;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
#box {
    text-align: left;
    font-size: 1rem;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
}
.hidden {
    display: none;
}
.aboout {
    margin-bottom: 2rem;
}
#content {
    display: flex;
}
.but {
    text-decoration: underline;
    cursor: pointer;
}


.abouthead{
      margin-top: 2rem;
      margin-bottom: 0.5rem ;

}


.desc_All:last-of-type {
    margin-bottom: 0px;
}

.padder {
    padding: $padding;
    padding-top:4rem;
    padding-right: $padding;
    text-align: left;
}

a{
    color:$green;
}

@media only screen and (max-width: 480px) {

    .padder {
        padding: $padding/2;
            padding-top:2rem;

        padding-right: 0;
    }

    .abouthead:first-of-type{
        margin-top: 0;
    }
}
</style>
