import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import SeeAll from "@/views/Seeall.vue";
import WordPage from "@/views/WordPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'The Dictionary of Obscure Ai Sorrows',
      metaTags: [
        {
          name: 'description',
          content: 'A dictionary of obscure AI sorrows generated by OpenAi’s GPT-3 and trained on entries from The Dictionary of Obscure Sorrows.'
        },
        {
          property: 'og:description',
          content: 'A dictionary of obscure AI sorrows generated by OpenAi’s GPT-3 and trained on entries from The Dictionary of Obscure Sorrows.'
        }
      ]
    }
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/seeall",
    name: "See All",
    component: SeeAll,
  },
  {
    path: '/:slug',
    name: 'WordPage',
    component: WordPage,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;
