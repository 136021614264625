<template>

    <div id="WoD">
       <div class="HomeEntry" ref="entry" v-bind:id="post._id" v-for="(post, index) in sortedPosts" v-bind:item="post" v-bind:index="index" v-bind:key="post._id">
                       <mr-scroll>

            <p class="mini-text" >No. {{post.Entry_No}}</p>
            <h1 class="word">
                <router-link :to="{ name: 'WordPage', params: { slug: post.Slug } }" class="name">{{ post.Name }}</router-link
                ><em class="type">{{ post.Type }}.</em>
            </h1>
            <p class="descri">
                {{ post.Desc }}
            </p>
            <p class="error" v-if="error">{{ error }}</p>
            <a class="relate_text_all" v-on:click="upVote(post._id, num)" :id="post._id">
                <svg
                    version="1.1"
                    class="heart"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 19 16.8"
                    style="enable-background:new 0 0 19 16.8;"
                    xml:space="preserve"
                >
                    <path
                        v-bind:style="{ fill: filling }"
                        class="st0"
                        d="M16.1,2.8L16.1,2.8c0.4,0.4,0.8,0.9,1,1.5c0.2,0.6,0.4,1.2,0.4,1.8s-0.1,1.2-0.4,1.8c-0.2,0.6-0.6,1.1-1,1.5
	l0,0l-6.3,6.1l0,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1l0,0L2.9,9.2l0,0C2.1,8.4,1.6,7.3,1.5,6.2
	C1.5,5,1.9,3.9,2.7,3c0.8-0.9,1.9-1.4,3.1-1.6C7,1.3,8.2,1.6,9.2,2.3l0.3,0.2l0.3-0.2c0.9-0.7,2.1-1,3.2-0.9
	C14.2,1.5,15.3,2,16.1,2.8z"
                    />
                </svg>

                <span class="relates" ref="counter">{{ post.Relates }} </span><span class="relatesText" ref="counter">Loading...</span>
            </a>
                </mr-scroll>

        </div>
    </div>

</template>

<script>
import PostService from "../PostService";
import json from "../assets/posts.json";


export default {
    
    // name: 'NewerWebsiteHome',

    computed: {
        sortedPosts: function() {
            return this.posts.filter((posts) => posts.Slug == this.slug);
        },
    },
    // directives: { DirectiveName },

    data() {
        return {
            posts: json,
            error: "",
            num: 1,
            counter: 0,
            likes: JSON.parse(localStorage.getItem("Likes")) || [],
            id: this.$route.params.id,
            slug: this.$route.params.slug,
            Week: 0,
        };
    },
    async created() {
        try {
            console.log("before");
            var currentdate = new Date();
            var oneJan = new Date(currentdate.getFullYear(), 0, 1);
            var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
            this.Week = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7) - 5;
        } catch (err) {
            this.error = err.message;
        }
    },
    async mounted() {
        try {
            this.posts = await PostService.getPosts();
            console.log(this.posts);
            this.$nextTick(() => {
                var likes = JSON.parse(localStorage.getItem("Likes")) || [];
                let Entry = document.getElementsByClassName("relate_text_all");
                console.log(Entry);

                for (let i = 0; i < Entry.length; i++) {
                    let id = Entry[i].getAttribute("id");
                    console.log(Entry[i]);

                    if (likes.includes(id)) {
                        console.log(Entry[i]);
                        var relateNumHeart = document.querySelector(`#${CSS.escape(id)} svg .st0`);
                        var relateNumText = document.querySelector(`#${CSS.escape(id)} .relatesText`);
                        var relateNumDiv = document.querySelector(`#${CSS.escape(id)} .relates`);
                        var relateNum = relateNumDiv.textContent;
                        relateNumHeart.style.fill = "#238235";
                        relateNumText.textContent = "people, including you, relate to this word";

                        if (relateNum == 1) {
                            relateNumText.textContent = "person, just you, relates to this word";
                        }
                    } else {
                        relateNumDiv = document.querySelector(`#${CSS.escape(id)} .relates`);
                        relateNum = relateNumDiv.textContent;
                        relateNumText = document.querySelector(`#${CSS.escape(id)} .relatesText`);
                        if (relateNum == 1) {
                            relateNumText.textContent = "person relates to this word";
                        } else {
                            relateNumText.textContent = "people relate to this word";
                        }
                    }
                }
            });
        } catch (err) {
            this.error = err.message;
        }
    },

    methods: {
        async upVote(id, num) {
            var likes = JSON.parse(localStorage.getItem("Likes")) || [];
            var relateNumHeart = document.querySelector(`#${CSS.escape(id)} svg .st0`);
            var relateNumDiv = document.querySelector(`#${CSS.escape(id)} .relates`);
            var relateNumText = document.querySelector(`#${CSS.escape(id)} .relatesText`);
            var relateNum = relateNumDiv.textContent;
            if (likes.includes(id) == true || likes == null) {
                num = -1;
                likes = likes.filter(function(a) {
                    return a !== id;
                });
                localStorage.setItem("Likes", JSON.stringify(likes));
                relateNumHeart.style.fill = "none";

                relateNum--;
                relateNumDiv.textContent = relateNum;

                relateNumText.textContent = "people relate to this word";
                if (relateNum == 1) {
                    relateNumText.textContent = "person relates to this word";
                }
            } else {
                num = 1;
                likes.push(id);
                localStorage.setItem("Likes", JSON.stringify(likes));
                relateNumHeart.style.fill = "#238235";

                relateNum++;
                relateNumDiv.textContent = relateNum;
                relateNumText.textContent = "people, including you, relate to this word";

                if (relateNum == 1) {
                    relateNumText.textContent = "person, just you, relates to this word";
                }
            }
            await PostService.upVote(id, num);
            var counter = document.getElementsByClassName("relates");
            counter.innerhtml;
            //this.posts = await PostService.getWeekly();
        },
    },
};
</script>

<style lang="scss">

#WoD {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.HomeEntry {
    width: 100%;
    padding: $padding;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    flex-direction: column;
    text-align: left;
    max-height: 100%;
}
.relates {
    display: inline-block;
    margin-right: 3px;
}
h1 {
    margin-top: 0.4rem;
    margin-bottom: 0rem;
    font-size: 2.5rem;
}
.name {
    color: $black;
    text-decoration: none;
}
.name:hover {
    color: $black;
    text-decoration: underline;
}


.heart {
    width: 1rem;
    margin-right: 0.5rem;
}

.st0 {
    stroke-width: 1.5px;
    stroke: $green;
    fill: none;
    overflow: visible;
}
.relate_text:hover .st0 {
    fill: $green;
}

@media only screen and (max-width: 480px) {
    .HomeEntry {
    width: 100%;
    padding:0px;
    padding-right: 0;
        padding-left: $padding/2;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: left;
}
.relates {
    display: inline-block;
    margin-right: 3px;
}
h1 {
    margin-top: 0.4rem;
    margin-bottom: 0rem;
    font-size: 2rem;
}
.name {
    color: $black;
    text-decoration: none;
}
.name:hover {
    color: $black;
    text-decoration: underline;
}
.type {
    margin-left: 1rem;
    font-weight: 500;
}

.relate_text {
    color: $green;
    margin-top: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.heart {
    width: 1rem;
    margin-right: 0.5rem;
}

.st0 {
    stroke-width: 1.5px;
    stroke: $green;
    fill: none;
    overflow: visible;
}
.relate_text:hover .st0 {
    fill: $green;
}

}


</style>
